const useQuestions = () => {
  const questions = [
    {
      id: 1,
      orderIndex: 0,
      translations: [
        {
          id: 1,
          lang: "en",
          title: "Що таке груповий збір від Жабки?",
          body: "Груповий збір коштів (Банка) від Жабки призначений виключно для особистого використання та не може бути використаний у будь-яких комерційних цілях. Платформа надає зручний спосіб організувати збір та переказувати кошти на різноманітні особисті потреби, забезпечуючи простоту управління та гнучкість налаштувань для ефективного збору коштів у спільноті. Групові збори (Банки) можуть бути організовані на відкриту чи конкретну суму або фіксованими платежами, чи обмежені по часу або безлімітні, чи все та й одразу))). Платформа дозволяє переказувати кошти без комісій на рахунок збору.",
          faq: "",
        },
        {
          id: 2,
          lang: "pl",
          title: "Що таке груповий збір від Жабки?",
          body: "Груповий збір коштів (Банка) від Жабки призначений виключно для особистого використання та не може бути використаний у будь-яких комерційних цілях. Платформа надає зручний спосіб організувати збір та переказувати кошти на різноманітні особисті потреби, забезпечуючи простоту управління та гнучкість налаштувань для ефективного збору коштів у спільноті. Групові збори (Банки) можуть бути організовані на відкриту чи конкретну суму або фіксованими платежами, чи обмежені по часу або безлімітні, чи все та й одразу))). Платформа дозволяє переказувати кошти без комісій на рахунок збору.",
        },
        {
          id: 3,
          lang: "uk",
          title: "Що таке груповий збір від Жабки?",
          body: "Груповий збір коштів (Банка) від Жабки призначений виключно для особистого використання та не може бути використаний у будь-яких комерційних цілях. Платформа надає зручний спосіб організувати збір та переказувати кошти на різноманітні особисті потреби, забезпечуючи простоту управління та гнучкість налаштувань для ефективного збору коштів у спільноті. Групові збори (Банки) можуть бути організовані на відкриту чи конкретну суму або фіксованими платежами, чи обмежені по часу або безлімітні, чи все та й одразу))). Платформа дозволяє переказувати кошти без комісій на рахунок збору.",
        },
      ],
    },
    {
      id: 2,
      orderIndex: 1,
      translations: [
        {
          id: 4,
          lang: "en",
          title: "Які ліміти на оплату та розмір збору?",
          body: "Ліміти для оплати та розмір групового збору регламентуються національним законодавством. В межах групового збору оплата є абсолютно безкоштовною для платників, що означає відсутність комісії сервісу. Варто враховувати, що деякі банки можуть стягувати додаткову комісію за переказ коштів з картки на карту. Поточні ліміти визначені на рівні не більше 29950,00 гривень за одну транзакцію, розмір банки обмежений у 5млн. гривень.",
        },
        {
          id: 5,
          lang: "pl",
          title: "Які ліміти на оплату та розмір збору?",
          body: "Ліміти для оплати та розмір групового збору регламентуються національним законодавством. В межах групового збору оплата є абсолютно безкоштовною для платників, що означає відсутність комісії сервісу. Варто враховувати, що деякі банки можуть стягувати додаткову комісію за переказ коштів з картки на карту. Поточні ліміти визначені на рівні не більше 29950,00 гривень за одну транзакцію, розмір банки обмежений у 5млн. гривень.",
        },
        {
          id: 6,
          lang: "uk",
          title: "Які ліміти на оплату та розмір збору?",
          body: "Ліміти для оплати та розмір групового збору регламентуються національним законодавством. В межах групового збору оплата є абсолютно безкоштовною для платників, що означає відсутність комісії сервісу. Варто враховувати, що деякі банки можуть стягувати додаткову комісію за переказ коштів з картки на карту. Поточні ліміти визначені на рівні не більше 29950,00 гривень за одну транзакцію, розмір банки обмежений у 5млн. гривень.",
        },
      ],
    },
    {
      id: 3,
      orderIndex: 2,
      translations: [
        {
          id: 7,
          lang: "en",
          title: "Як вивести кошти зі збору та які ліміти?",
          body: "Для виведення зібраних коштів у вас є можливість здійснювати повний або частковий переказ коштів на будь-який картковий рахунок банків України через введення номеру картки або на карту мобільного гаманця Apple Pay або Google Pay, навіть до завершення збору, якщо це необхідно. Виведення можливе на будь-яку карту українського банку, але при цьому сервіс утримує 2% від суми транзакції. Ліміт однієї транзакції не може перевищувати 29950гривень. Також є опція повернення коштів, якщо мета збору не була досягнута, за винятком комісії сервісу. Важливо зауважити, що отримані кошти можуть розглядатись як додатковий дохід і слід включити їх у річну декларацію. Зазначте, що існує щоденний ліміт на виведення коштів на одну картку будь-якого українського банку, що не перевищує 29950 гривень.",
        },
        {
          id: 8,
          lang: "pl",
          title: "Як вивести кошти зі збору та які ліміти?",
          body: "Для виведення зібраних коштів у вас є можливість здійснювати повний або частковий переказ коштів на будь-який картковий рахунок банків України через введення номеру картки або на карту мобільного гаманця Apple Pay або Google Pay, навіть до завершення збору, якщо це необхідно. Виведення можливе на будь-яку карту українського банку, але при цьому сервіс утримує 2% від суми транзакції. Ліміт однієї транзакції не може перевищувати 29950гривень. Також є опція повернення коштів, якщо мета збору не була досягнута, за винятком комісії сервісу. Важливо зауважити, що отримані кошти можуть розглядатись як додатковий дохід і слід включити їх у річну декларацію. Зазначте, що існує щоденний ліміт на виведення коштів на одну картку будь-якого українського банку, що не перевищує 29950 гривень.",
        },
        {
          id: 9,
          lang: "uk",
          title: "Як вивести кошти зі збору та які ліміти?",
          body: "Для виведення зібраних коштів у вас є можливість здійснювати повний або частковий переказ коштів на будь-який картковий рахунок банків України через введення номеру картки або на карту мобільного гаманця Apple Pay або Google Pay, навіть до завершення збору, якщо це необхідно. Виведення можливе на будь-яку карту українського банку, але при цьому сервіс утримує 2% від суми транзакції. Ліміт однієї транзакції не може перевищувати 29950гривень. Також є опція повернення коштів, якщо мета збору не була досягнута, за винятком комісії сервісу. Важливо зауважити, що отримані кошти можуть розглядатись як додатковий дохід і слід включити їх у річну декларацію. Зазначте, що існує щоденний ліміт на виведення коштів на одну картку будь-якого українського банку, що не перевищує 29950 гривень.",
        },
      ],
    },
  ];

  return { questions };
};

export default useQuestions;
