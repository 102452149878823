import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PhoneSlider from "components/home/PhoneSlider";
import UndelineLinkSection from "components/links/UnderlineLinkSection";
import slideEn from "images/en/home/header/main.jpg";
import slidePl from "images/pl/home/header/main.png";
import slideUk from "images/uk/home/header/main.png";
import collect from "images/en/navigation/collect.svg";
import useCurrLang from "hooks/useCurrLang";

const Main = () => {
  const { t } = useTranslation();
  const currLang = useCurrLang();

  const images = {
    en: slideEn,
    pl: slidePl,
    uk: slideUk,
  };

  return (
    <header>
      <div
        className="sliding-main-image"
        style={{ backgroundImage: `url(${images[currLang]})` }}
      ></div>
      <div className="download-section">
        <div className="left-download-section">
          <h1>
            <Trans i18nKey="home.header.Title">
              Many banks — <span>Orovera</span> is <span>one</span>
            </Trans>
          </h1>
        </div>
        <div className="right-download-section">
          <p>
            <Trans i18nKey="home.header.Description">
              <span>🐸</span> In the chat - transfer <span>💰💸</span> to a card
              easily and safely! Start your own fundraising for a cool idea,
              join forces, and achieve a common goal together right now{" "}
              <span>:)</span>
            </Trans>
          </p>
          <div
            className="buttons"
            style={{
              justifyContent: "flex-start",
            }}
          >
            <Link
              to="https://jar.zhabka.ua"
              className="download-app-button"
              target="_blank"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                maxWidth: "200px",
              }}
            >
              <img src={collect} alt="collect" />
              {t("home.nav.Moneybox")}
            </Link>
          </div>
        </div>
      </div>
      <PhoneSlider />
      <UndelineLinkSection to="/" name={t("home.header.Learn more")} />
    </header>
  );
};

export default Main;
