import TermsAndPrivacyTemplate from "components/terms-and-privacy/terms-and-privacy-template";
import { Trans, useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <section id="privacy-policy">
      <TermsAndPrivacyTemplate
        title={t("privacy-policy.Title")}
        items={[
          {
            title: t("privacy-policy.sections.1.Title"),
            body: (
              <Trans i18nKey={"privacy-policy.sections.1.Body"}>
                <p>
                  1. Додаток «Жабка» (далі – Додаток) належить на праві
                  власності ТОВ "4ек", код ЄДРПОУ 44336843 (далі – Компанія) .
                  Всіоб'єкти права інтелектуальної власності, які містяться у
                  додатку, окрім випадків, коли про це окремо зазначено,
                  належать на праві власності Компанії.
                </p>
                <p>
                  2. Дана Політика конфіденційності та захисту персональних
                  даних (далі – Політика конфіденційності) є чинною виключно в
                  рамках Правил користуванняДодатком та не поширюється на web –
                  сайти третіх осіб.
                </p>
                <p>
                  3. Дана Політика Політикиконфіденційності є комплексом
                  правових, адміністративних , організаційних, технічних та
                  інших заходів, які вживаєКомпанія з метою
                  забезпеченняконфіденційності та захисту персональнихданих
                  фізичних осіб - споживачів, які є користувачами Додатку, окрім
                  випадків, коли така інформація особисто та
                  свідоморозголошується споживачем – користувачем Додатку.
                </p>
                <p>
                  4. Метою впровадження Політикиконфіденційності та захисту
                  персональнихданих є недопущення публічного доступу та
                  розголошення інформації, володільцемякої є споживач.
                </p>
                <p>
                  5. Компанія вживає всіх можливих заходів з метою забезпечення
                  виконання заходів, передбачених даним розділом
                  Політикиконфіденційності.
                </p>
              </Trans>
            ),
          },
          {
            title: t("privacy-policy.sections.2.Title"),
            body: (
              <Trans i18nKey={"privacy-policy.sections.2.Body"}>
                <p>
                  1. З метою реалізації функцій та завданьДодатку та інших
                  цілей, пов'язаних з цивільно- правовими відносинами
                  міжКомпанією та споживачем, споживач, користуючись даним
                  Додатком та послугами, для надання яких створенийДодаток,
                  надає Компанії дозвіл на збирання, накопичення, обробку,
                  зберігання та використання (в тому числі з використанням
                  автоматизованих засобів) персональних даних, володільцем яких
                  вінявляється.
                </p>
                <p>
                  1.1. З метою реалізації функцій та завданьДодатку та інших
                  цілей, пов'язаних з цивільно- правовими відносинами
                  міжКомпанією та споживачем, споживач, користуючись даним
                  Додатку та послугами, для надання яких створенийДодаток, надає
                  Компанії дозвіл на передачу власних персональних данихтретім
                  особам.
                </p>
                <p>
                  2. Згода споживача, яка надаєтьсяКомпанії на збирання,
                  обробку, зберіганнята використання (в тому числі з
                  використанням автоматизованих засобів) персональних даних та
                  їх передачу третімособам оформлюється шляхом проставлення
                  споживачем відмітки про надання дозволу на збирання,
                  накопичення, обробку, зберігання та використання своїх
                  персональних даних.
                </p>
                <p>
                  3. До персональних даних, на збирання, накопичення, обробку,
                  зберігання та використання яких (в тому числі з використанням
                  автоматизованих засобів) споживач надає дозвіл Компанії
                  входить:
                </p>
                <p>а) прізвище, ім'я, по-батькові;</p>
                <p>б) номери засобів зв'язку (телефон, e-mail);</p>
                <p>в) сфера діяльності;</p>
                <p>г) Інша інформація, яка самостійновноситься споживачем.</p>
              </Trans>
            ),
          },
          {
            title: t("privacy-policy.sections.3.Title"),
            body: (
              <Trans i18nKey={"privacy-policy.sections.3.Body"}>
                <p>text</p>
                <p>text</p>
              </Trans>
            ),
          },
          {
            title: t("privacy-policy.sections.4.Title"),
            body: (
              <Trans i18nKey={"privacy-policy.sections.4.Body"}>
                <p>text</p>
                <p>text</p>
              </Trans>
            ),
          },
          {
            title: t("privacy-policy.sections.5.Title"),
            body: (
              <Trans i18nKey={"privacy-policy.sections.5.Body"}>
                <p>text</p>
                <p>text</p>
              </Trans>
            ),
          },
          {
            title: t("privacy-policy.sections.6.Title"),
            body: (
              <Trans i18nKey={"privacy-policy.sections.6.Body"}>
                <p>text</p>
                <p>text</p>
              </Trans>
            ),
          },
        ]}
      />
    </section>
  );
};

export default PrivacyPolicy;
