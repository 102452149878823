import Button from "./Button";
import apple from "icons/apple.svg";

const AppStoreButton = () => {
  return (
    <Button
      href="https://apps.apple.com/ua/app/%D0%B6%D0%B0%D0%B1%D0%BA%D0%B0/id1578714013"
      target="_blank"
      onClick={() => {}}
      className="download-app-button"
    >
      <img src={apple} alt="app store" />
      <span>App Store</span>
    </Button>
  );
};

export default AppStoreButton;
