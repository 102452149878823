import Button from "./Button";
import googlePlay from "icons/google-play.svg";

const GooglePlayButton = () => {
  return (
    <Button
      href="https://play.google.com/store/apps/details?id=ua4ek.board&pcampaignid=web_share"
      target="_blank"
      onClick={() => {}}
      className="download-app-button"
    >
      <img src={googlePlay} alt="google play" />
      <span>Google Play</span>
    </Button>
  );
};

export default GooglePlayButton;
