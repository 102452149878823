import TermsAndPrivacyTemplate from "components/terms-and-privacy/terms-and-privacy-template";
import { Trans, useTranslation } from "react-i18next";

const TermsOfService = () => {
  const { t } = useTranslation();

  return (
    <section id="terms-of-services">
      <TermsAndPrivacyTemplate
        title={t("terms-of-services.Title")}
        items={[
          {
            title: t("terms-of-services.sections.1.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.1.Body"}>
                <p>text</p>
                <p>text</p>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.2.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.2.Body"}>
                <p>text</p>
                <p>text</p>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.3.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.3.Body"}>
                <p>text</p>
                <p>text</p>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.4.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.4.Body"}>
                <p>text</p>
                <p>text</p>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.5.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.5.Body"}>
                <p>text</p>
                <p>text</p>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.6.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.6.Body"}>
                <p>text</p>
                <p>text</p>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.7.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.7.Body"}>
                <p>text</p>
                <p>text</p>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.8.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.8.Body"}>
                <p>text</p>
                <p>text</p>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.9.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.9.Body"}>
                <p>text</p>
                <p>text</p>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.10.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.10.Body"}>
                <p>text</p>
                <p>text</p>
              </Trans>
            ),
          },
          {
            title: t("terms-of-services.sections.11.Title"),
            body: (
              <Trans i18nKey={"terms-of-services.sections.11.Body"}>
                <p>text</p>
                <p>text</p>
              </Trans>
            ),
          },
        ]}
      />
    </section>
  );
};

export default TermsOfService;
