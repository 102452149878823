import { Link } from "react-router-dom";
import facebook from "images/en/social/facebook.svg";
import instagram from "images/en/social/instagram.svg";
import linkedin from "images/en/social/linkedin.svg";
import tiktok from "images/en/social/tiktok.svg";

const FooterSocials = () => {
  return (
    <ul className="social">
      <li>
        <Link
          to="https://www.facebook.com/profile.php?id=100075907989317"
          target="_blank"
        >
          <img src={facebook} alt="facebook" />
        </Link>
      </li>
      <li>
        <Link
          to="https://www.instagram.com/zhabkaua?igshid=M2RkZGJiMzhjOQ%3D%3D"
          target="_blank"
        >
          <img src={instagram} alt="instagram" />
        </Link>
      </li>
      <li>
        <Link
          to="https://www.linkedin.com/company/%D0%B6%D0%B0%D0%B1%D0%BA%D0%B0-zhabka/about/"
          target="_blank"
        >
          <img src={linkedin} alt="linkedin" />
        </Link>
      </li>
      <li>
        <Link
          to="https://www.tiktok.com/@orovera?_t=8hnAmW88QDu&_r=1"
          target="_blank"
        >
          <img src={tiktok} alt="tiktok" />
        </Link>
      </li>
    </ul>
  );
};

export default FooterSocials;
