import { Routes, Route } from "react-router-dom";
import { NavPath } from "types/nav";
import Layout from "./components/Layout";
import Home from "pages/Home";
import TermsOfService from "pages/TermsOfService";
import FAQ from "pages/FAQ";
import PrivacyPolicy from "pages/PrivacyPolicy";
import ForShareholders from "pages/ForShareholders";
import NewsShow from "pages/NewsShow";
import ContactUs from "pages/ContactUs";
import useScrollToTop from "hooks/useScrollToTop";

const App = () => {
  useScrollToTop();

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path={NavPath.FAQ} element={<FAQ />} />
        <Route path={NavPath.PrivacyPolicy} element={<PrivacyPolicy />} />
        <Route path={NavPath.TermsOfService} element={<TermsOfService />} />
        <Route path={NavPath.ForShareholders} element={<ForShareholders />} />
        <Route path={NavPath.NewsShow} element={<NewsShow />} />
        <Route path={NavPath.ContactUs} element={<ContactUs />} />
      </Route>
    </Routes>
  );
};

export default App;
