import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "icons/logo-black.svg";
import logoUk from "icons/logo-uk-black.svg";
import AppStoreButton from "./buttons/AppStoreButton";
import GooglePlayButton from "./buttons/GooglePlayButton";
import useCurrLang from "hooks/useCurrLang";
import { NavPath } from "types/nav";
import FooterSocials from "./FooterSocials";

const Footer = () => {
  const { t } = useTranslation();
  const currLang = useCurrLang();

  const logoIcon = {
    en: logo,
    pl: logo,
    uk: logoUk,
  };

  return (
    <div className="footer-wrapper">
      <div className="container">
        <div className="footer">
          <div className="logo">
            <div className="logo-wrapper">
              <img src={logoIcon[currLang]} alt="logo" />
            </div>
          </div>
          <div className="footer-nav">
            <div className="top-footer">
              <div className="features">
                <h5 className="mb-sm">{t("footer.Features")}</h5>
                <ul>
                  <li>
                    <Link to="https://jar.zhabka.ua" target="_blank">
                      {t("footer.Moneybox")}
                    </Link>
                  </li>
                  <li>
                    <Link to={NavPath.Home + "#keyboard-section"}>
                      {t("footer.Financial keyboard")}
                    </Link>
                  </li>
                  <li>
                    <Link to={NavPath.Home + "#personal-payments-section"}>
                      {t("footer.Personal payments")}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="company">
                <h5 className="mb-sm">{t("footer.Company")}</h5>
                <ul>
                  <li>
                    <Link to={NavPath.FAQ}>{t("footer.FAQ")}</Link>
                  </li>
                  <li>
                    <Link to={NavPath.ForShareholders}>
                      {t("footer.Partners")}
                    </Link>
                  </li>
                  <li>
                    <Link to={NavPath.ContactUs}>{t("footer.Contact Us")}</Link>
                  </li>
                </ul>
              </div>
              <div className="download">
                <h5 className="mb-sm">{t("footer.Download App")}</h5>
                <AppStoreButton />
                <GooglePlayButton />
              </div>
            </div>
            <div className="bottom-footer">
              <ul className="info">
                <li>
                  <Link to={NavPath.PrivacyPolicy}>
                    {t("footer.Privacy Policy")}
                  </Link>
                </li>
                <li>
                  <Link to={NavPath.TermsOfService}>
                    {t("footer.Terms of Service")}
                  </Link>
                </li>
                <li className="d-none d-lg-flex">
                  © 2023. {t("general.Orovera")}
                </li>
              </ul>
              <FooterSocials />
              <ul className="info info-orovera d-lg-none">
                <li>© 2023. {t("general.Orovera")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
