import Sidebar from "components/Sidebar";
import { FC, ReactNode, useCallback, useMemo, useState } from "react";
import blurBg from "images/en/terms-and-privacy/blur.png";
import MobileMenuModal from "components/modals/MobileMenuModal";
import ModalMenuBtn from "components/buttons/MobileMenuBtn";

interface TermsAndPrivacyTemplateProps {
  title: string;
  items: {
    title: string;
    body: string | ReactNode;
  }[];
}

const getElementId = (key: string, index: number) => {
  return key.toLowerCase() + index;
};

const TermsAndPrivacyTemplate: FC<TermsAndPrivacyTemplateProps> = (props) => {
  const [isMobileMenuModalOpen, setMobileMenuOpen] = useState<boolean>(false);

  const toggleMobileMenu = useCallback(() => {
    setMobileMenuOpen(!isMobileMenuModalOpen);
  }, [isMobileMenuModalOpen]);

  const itemsToRender = useMemo(() => {
    return props.items.map((it, i) => {
      return (
        <div id={getElementId(it.title, i)} className="terms-and-privacy__item">
          <h3>{it.title}</h3>

          <p>{it.body}</p>
        </div>
      );
    });
  }, [props.items]);

  const sidebarItems = useMemo(() => {
    return props.items.map((it, i) => {
      return {
        title: it.title,
        onClick: () => {
          const el = document.getElementById(getElementId(it.title, i));
          el?.scrollIntoView(false);
          setMobileMenuOpen(false);
        },
      };
    });
  }, [props.items]);

  return (
    <div className="terms-and-privacy">
      <MobileMenuModal
        title={"Content"}
        isOpen={isMobileMenuModalOpen}
        items={sidebarItems}
      />
      <Sidebar items={sidebarItems} />

      <div className="content">
        <img src={blurBg} alt="blur-bg" />

        <header>
          <div className="title-container">
            <h1 className="title">{props.title}</h1>
          </div>
        </header>

        {itemsToRender}
      </div>

      <ModalMenuBtn
        isMenuOpen={isMobileMenuModalOpen}
        onClick={toggleMobileMenu}
      />
    </div>
  );
};

export default TermsAndPrivacyTemplate;
